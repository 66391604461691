<template>
  <span class="followup-record-wrap">
    <!-- <el-popover
      placement="left"
      width="500"
      trigger="click"
      v-model="togglePopover"
    >
      <el-table :data="recordLists" v-loading="loading" height="300px">
        <el-table-column
          property="name"
          label="内容"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column
          property="createdAt"
          label="发布时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.createdAt | date_time }}
          </template>
        </el-table-column>
      </el-table>
      <span class="text-nav" slot="reference" @click="onShowDetail">{{
        loading ? "加载中..." : `${total} 条`
      }}</span>
    </el-popover> -->
    <span class="text-nav" @click="onShowDetail">{{
      loading ? "加载中..." : `${total}`
    }}</span>
  </span>
</template>

<script>
import { GetClientFollowUpsRecordList } from "@/views/client/clientFollowUpsRecord/api";
import { isEmprty } from "@/utils/validate";
export default {
  name: "ClientFollowUpRecordListItem",
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      togglePopover: false,
      loading: false,
      total: 0,
      recordLists: [],
    };
  },
  watch: {
    id: {
      handler(val) {
        if (!isEmprty(val)) {
          this.getClientFollowUpRecord();
        }
      },
    },
  },
  created() {
    if (!isEmprty(this.id)) {
      this.getClientFollowUpRecord();
    }
  },
  methods: {
    getClientFollowUpRecord() {
      this.loading = true;
      GetClientFollowUpsRecordList({
        page: 0,
        size: 999,
        clientId: this.id,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.recordLists = content;
          this.total = totalElements;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onShowDetail() {
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: this.id,
          tabId: 1,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>