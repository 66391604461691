import {
    ToggleMultipleStartClients,
    ToggleMultipleDisableClients,
    ToggleStartClientById,
    PutClientTagsById,
    GetDisableCheck,
    GetUpdateCheck,
    GetUpdateOwnerCheck,
    GetUpdateStageCheck,
    GetSignCheck,
    // GetCheckClientClaimed
} from "../api";
import { ShowApiError } from "@/request/error";
import { FormatMultipleItemIds, GetTableDisableMessage } from "@/utils/table";
export default {
    data() {
        return {
            showExcelImportDialog: false,
            showStageDialog: false,
            showOwnerDialog: false,
            showTagDialog: false,
            showSingDialog: false,
            showMultipleOwnerDialog: false,
            multipleDisableLoading: false,
            multipleUndisableLoading: false,
            multipleDisableLoadingText: ""
        }
    },
    directives: {
        checkClaim: {
            // 指令的定义
            inserted: function (el,binding) {
                console.log('拿到对象', binding.value)
                el.focus()
            }
        }
    },
    methods: {
        getControlColumn() {
            return {
                canDisableChecking: false,
                canUpdateChecking: false,
                canUpdateOwnerChecking: false,
                canUpdateStageChecking: false,
                canUpdateSignChecking: false,
                canUpdateStarChecking: false,
                canUpdate: false,
                canDisable: false,
                canStage: false,
                canSign: false,
                canOwner: false,
                canStar: false,
            };
        },
        editRow(row) {
            if (row.canUpdate) {
                this.$router.push({
                    name: "ClientsForm",
                    params: {
                        id: row.id,
                        type: "CLIENT",
                    },
                });
                return false;
            }
            row.canUpdateChecking = true;
            GetUpdateCheck(row.id)
                .then((res) => {
                    row.canUpdate = res.data;
                    if (res.data) {
                        row.canUpdateChecking = false;
                        this.$router.push({
                            name: "ClientsForm",
                            params: {
                                id: row.id,
                                type: "CLIENT",
                            },
                        });
                    } else {
                        row.canUpdateChecking = false;
                        this.$message.warning("没有权限执行此操作!");
                    }
                })
                .catch(() => {
                    row.canUpdate = false;
                    this.$message.warning("权限校验异常，请稍后再试");
                })
                .finally(() => {
                    row.canUpdateChecking = false;
                });
        },
        ToggleDisableClient(row, flag = false) {
            return new Promise((resolve, reject) => {
                let title = flag ? '禁用提示' : '启用提示'
                let massage = flag ? '是否确定要禁用该客户' : '是否确定要启用该客户'
                row.canDisableChecking = true;
                GetDisableCheck(row.id).then(res => {
                    if (res.data) {
                        row.canDisableChecking = false;
                        this.$msgbox({
                            title: title,
                            message: massage,
                            showCancelButton: true,
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            beforeClose: (action, instance, done) => {
                                if (action === "confirm") {
                                    instance.confirmButtonLoading = true;
                                    instance.confirmButtonText = "执行中...";
                                    ToggleMultipleDisableClients([row.id], flag)
                                        .then((res) => {
                                            this.$message.success("操作成功!");
                                            resolve(res);
                                        })
                                        .catch((err) => {
                                            ShowApiError("操作请求错误", err);
                                            reject(err);
                                        }).finally(() => {
                                            done();
                                            instance.confirmButtonLoading = false;
                                            instance.confirmButtonText = "";
                                        })
                                } else {
                                    instance.confirmButtonLoading = false;
                                    instance.confirmButtonText = "";
                                    done();
                                }
                            },
                        });
                    } else {
                        row.canDisableChecking = false;
                        this.$message.warning("没有权限执行此操作!");
                        reject();
                    }
                }).catch(() => {
                    row.canDeleteChecking = false;
                    this.$message.warning("权限校验异常，请稍后再试");
                    reject();
                });

            })
        },
        disableRow(row) {
            this.ToggleDisableClient(row, true).then(() => {
                this.onRefresh();
            });
        },
        enableRow(row) {
            this.ToggleDisableClient(row, false).then(() => {
                this.onRefresh();
            });

        },
        onCheckItemDisable() {
            return new Promise((resolve) => {
                this.multipleSelected.forEach((item) => {
                    item.canDisableChecking = true;
                    GetDisableCheck(item.id)
                        .then((res) => {
                            if (res.data) {
                                item.canDisable = res.data;
                            }
                        })
                        .finally(() => {
                            item.canDisableChecking = false;
                        });
                });
                let timer = null;
                timer = setInterval(() => {
                    let flag = this.multipleSelected.every(
                        (item) => item.canDisableChecking === false
                    );
                    if (flag) {
                        clearInterval(timer);
                        resolve();
                    }
                }, 1000);
            });
        },
        // 批量：启用/禁用
        onMultipleToggleDisable(flag = false) {
            if (this.multipleSelected.length <= 0) {
                return false;
            }
            this.multipleDisableLoading = flag;
            this.multipleUndisableLoading = !flag;
            this.multipleDisableLoadingText = "正在校验操作许可...";
            this.onCheckItemDisable().then(() => {
                let { ids, nameString, unableControl } = FormatMultipleItemIds({
                    multipleSelectes: this.multipleSelected,
                    nameKey: "name",
                });
                let message = GetTableDisableMessage({
                    total: this.multipleSelected.length,
                    ableControlCount: ids.length,
                    unableControlCount: unableControl.length,
                    nameString: nameString,
                    flag: flag,
                    ableText: "启用",
                    unAbleText: "禁用",
                });
                this.multipleDisableLoadingText = "检验完成，请确认...";
                this.$msgbox({
                    title: `${flag ? "禁用" : "启用"}提示`,
                    message: message,
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            if (ids.length <= 0) {
                                this.$message.info("无有效数据可操作");
                                done();
                                this.multipleDisableLoading = false;
                                this.multipleUndisableLoading = false;
                                this.multipleDisableLoadingText = "";
                                instance.confirmButtonLoading = false;
                                instance.confirmButtonText = "";
                                return;
                            }
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = "执行中...";
                            ToggleMultipleDisableClients(ids, flag)
                                .then(() => {
                                    this.$message.success("操作成功");
                                    this.multipleSelected = [];
                                    this.onRefresh();
                                })
                                .catch((err) => {
                                    ShowApiError("操作请求错误", err);
                                }).finally(() => {
                                    done();
                                    instance.confirmButtonLoading = false;
                                    instance.confirmButtonText = "";
                                    this.multipleDisableLoading = false;
                                    this.multipleUndisableLoading = false;
                                    this.multipleDisableLoadingText = "";
                                });
                        } else {
                            this.multipleDisableLoading = false;
                            this.multipleUndisableLoading = false;
                            this.multipleDisableLoadingText = "";
                            instance.confirmButtonLoading = false;
                            instance.confirmButtonText = "";
                            done();
                        }
                    },
                });
            })
        },
        ToggleStartClient(id = "", flag = false) {
            return new Promise((resolve, reject) => {
                ToggleStartClientById(id, flag)
                    .then((res) => {
                        this.$message.success("设置成功!");
                        resolve(res);
                    })
                    .catch((err) => {
                        ShowApiError("星标设置操作异常", err);
                        reject(err);
                    });
            })

        },
        handleStartClient(row) {
            this.ToggleStartClient(row.id, true).then(() => {
                row.star = true;
            });
        },
        handleUnStartClient(row) {
            this.ToggleStartClient(row.id, false).then(() => {
                row.star = false;
            });
        },

        // 批量：星标/取消星标
        onMultipleStartClient(flag = false) {
            if (this.multipleSelected.length <= 0) {
                return false;
            }
            let { ids, nameString, unableControl } = FormatMultipleItemIds({
                multipleSelectes: this.multipleSelected,
                nameKey: "name",
                conditionsKey: "",
            });
            let message = GetTableDisableMessage({
                total: this.multipleSelected.length,
                ableControlCount: ids.length,
                unableControlCount: unableControl.length,
                nameString: nameString,
                flag: flag,
                ableText: "取消星标",
                unAbleText: "设为星标",
            });
            this.$msgbox({
                title: `${flag ? "设为星标" : "取消星标"}提示`,
                message: message,
                showCancelButton: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                beforeClose: (action, instance, done) => {
                    if (action === "confirm") {
                        if (ids.length <= 0) {
                            this.$message.info("无有效数据可操作");
                            done();
                            return;
                        }
                        this.toggleStartLoading = true;
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = "执行中...";
                        ToggleMultipleStartClients(ids, flag)
                            .then(() => {
                                this.$message.success("操作成功");
                                this.multipleSelected = [];
                                this.toggleStartLoading = false;
                                instance.confirmButtonLoading = false;
                                instance.confirmButtonText = "";
                                done();
                                this.onRefresh();
                            })
                            .catch((err) => {
                                done();
                                this.toggleStartLoading = false;
                                instance.confirmButtonLoading = false;
                                instance.confirmButtonText = "";
                                ShowApiError("操作请求错误", err);
                            });
                    } else {
                        instance.confirmButtonLoading = false;
                        instance.confirmButtonText = "";
                        done();
                    }
                },
            });
        },
        handleSetClientTag(id = "", tagIds = []) {
            return new Promise((resolve, reject) => {
                PutClientTagsById(id, tagIds).then(() => {
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })

        },
        handleSignDialog(row) {
            if (row.canSign) {
                this.currentRow = row;
                this.showSingDialog = true;
                return false;
            }
            row.canUpdateSignChecking = true;
            GetSignCheck(row.id)
                .then((res) => {
                    row.canSign = res.data;
                    if (res.data) {
                        this.currentRow = row;
                        this.showSingDialog = true;
                    } else {
                        this.$message.warning("没有权限执行此操作!");
                        this.currentRow = {};
                        this.showSingDialog = false;
                    }
                    row.canUpdateSignChecking = false;
                })
                .catch(() => {
                    this.$message.warning("检验异常，请稍后重试!");
                    row.canSign = false;
                    row.canUpdateSignChecking = false;
                });
        },
        onSignFormCancel() {
            this.showSingDialog = false;
            this.currentRow = {};
        },
        onSignFormSuccess() {
            this.showSingDialog = false;
            this.currentRow = {};
            this.onRefresh();
        },
        handleStageDialog(row) {
            if (row.canStage) {
                this.currentRow = row;
                this.showStageDialog = true;
                return false;
            }
            row.canUpdateStageChecking = true;
            GetUpdateStageCheck(row.id)
                .then((res) => {
                    row.canStage = res.data;
                    if (res.data) {
                        this.currentRow = row;
                        this.showStageDialog = true;
                    } else {
                        this.$message.warning("没有权限执行此操作!");
                        this.currentRow = {};
                        this.showStageDialog = false;
                    }
                    row.canUpdateStageChecking = true;
                })
                .catch(() => {
                    this.$message.warning("检验异常，请稍后重试!");
                    row.canStage = false;
                    row.canUpdateStageChecking = false;
                });
        },
        onStageFormCancel() {
            this.showStageDialog = false;
            this.currentRow = {};
        },
        onStageFormSuccess() {
            this.showStageDialog = false;
            this.currentRow = {};
            this.onRefresh();
        },
        handleOwnerDialog(row) {
            if (row.canOwner) {
                this.currentRow = row;
                this.showOwnerDialog = true;
                return false;
            }
            row.canUpdateOwnerChecking = true;
            GetUpdateOwnerCheck(row.id)
                .then((res) => {
                    row.canUpdateOwnerChecking = false;
                    row.canOwner = res.data;
                    if (res.data) {
                        this.currentRow = row;
                        this.showOwnerDialog = true;
                    } else {
                        this.currentRow = {};
                        this.showOwnerDialog = false;
                        this.$message.warning("没有权限执行此操作!");
                    }
                })
                .catch(() => {
                    this.$message.warning("检验异常，请稍后重试!");
                    row.canOwner = false;
                    row.canUpdateOwnerChecking = false;
                });
        },
        onOwnerFormCancel() {
            this.showMultipleOwnerDialog = false;
            this.showOwnerDialog = false;
            this.currentRow = {};
        },
        onOwnerFormSuccess() {
            this.showMultipleOwnerDialog = false;
            this.showOwnerDialog = false;
            this.multipleSelected = [];
            this.currentRow = {};
            this.onRefresh();
        },
        onShowTagDialog(row) {
            this.currentRow = row;
            this.tagIds = row.tags.map((item) => item.id);
            this.showTagDialog = true;
        },
        onSetTagCancel() {
            this.showTagDialog = false;
            this.tagIds = [];
            this.currentRow = {};
        },
        onSetTagSuccess(ids) {
            this.handleSetClientTag(this.currentRow.id, ids).then(() => {
                this.$message.success("设置成功");
                this.showTagDialog = false;
                this.tagIds = [];
                this.currentRow = {};
                this.onRefresh();
            });
        },

    }
}