<template>
  <el-form
    :model="form"
    :rules="rules"
    status-icon
    ref="ruleForm"
    label-width="140px"
    class="company-ruleForm"
    :disabled="submitting"
  >
    <el-form-item label="签约人" prop="signedUserId">
      <BasicSelect v-model="form.signedUserId" :allOptions="ownerOptions" />
    </el-form-item>
    <el-form-item label="签约时间" prop="signedAt">
      <el-date-picker
        style="width: 100%"
        v-model="form.signedAt"
        type="date"
        placeholder="请选择"
      >
      </el-date-picker>
    </el-form-item>

    <el-form-item style="text-align: right">
      <el-button @click="onCancel" :disabled="submitting">关 闭</el-button>
      <el-button
        v-if="!isEmprty(clientId)"
        type="primary"
        :loading="submitting"
        @click="submitForm('ruleForm')"
        >提交</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { GetClientOwnersAll } from "@/views/client/clientOwners/api";
import { PutClientSignById } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import BasicSelect from "@/components/BasicSelect";
export default {
  components: { BasicSelect },
  props: {
    clientId: {
      type: [Number, String],
      default: "",
    },
    signedAt: {
      type: [Object, String],
      default: () => {
        return null;
      },
    },
    signedUserId: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      loading: false,
      submitting: false,
      ownerOptions: [],
      form: {
        signedAt: null,
        signedUserId: null,
      },
      currentId: null,
      rules: {
        signedAt: [
          {
            required: true,
            message: "请选择签约时间",
            trigger: ["blur", "change"],
          },
        ],
        signedUserId: [
          {
            required: true,
            message: "请选择签约用户",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.initFormOptions();
  },
  watch: {
    clientId: {
      handler() {
        if (this.clientId !== this.currentId) {
          this.currentId = this.clientId;
          this.initValue();
        }
      },
      immediate: true,
    },
  },
  methods: {
    isEmprty,
    initValue() {
      if (!isEmprty(this.signedAt) && !isEmprty(this.signedUserId)) {
        this.form.signedAt = this.signedAt;
        this.form.signedUserId = this.signedUserId;
      } else {
        this.inintForm();
      }
    },
    initFormOptions() {
      GetClientOwnersAll().then((res) => {
        this.ownerOptions = res.data;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutClientSignById(this.currentId, this.form)
            .then((res) => {
              this.submitting = false;
              this.inintForm();
              this.$message.success("操作成功");
              this.onEmitSuccess(res.data);
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("设置失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    onCancel() {
      this.inintForm();
      this.$emit("cancel");
    },
    onEmitSuccess(data = {}) {
      this.$emit("success", data);
    },
    inintForm() {
      this.form = {
        signedAt: null,
        signedUserId: null,
      };
      this.$nextTick(() => {
        this.$refs["ruleForm"].resetFields();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.file-content-list {
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 20px;
}
</style>